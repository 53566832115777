const routes = [
  {
    path: "/wallets/view-wallet",
    name: "Transaction History",
    component: () => import("@/components/business/wallets/WalletTransactions"),
    meta: {
      title: "Tanda | Wallet Payments",
      requiresAuth: true,
    },
  },
  {
    path: "wallet",
    name: "Wallet Balance",
    component: () => import("@/components/business/wallets/index"),
    meta: {
      title: "Tanda | Wallets",
      requiresAuth: true,
    },

    children: [
      {
        path: "/wallets",
        name: "Accounts",
        component: () => import("@/components/business/wallets/Wallets"),
        meta: {
          title: "Tanda | Balances",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
