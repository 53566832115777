const routes = [
  {
    path: "/settings",
    name: "Settings",
    component: () => import("@/components/business/settings"),
    meta: {
      title: "Tanda | Settings",
      requiresAuth: true,
    },
    children: [
      {
        path: "/settings",
        name: "Settings",
        component: () => import("@/components/business/settings/ipns"),
        meta: {
          title: "Tanda | IPNS",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
