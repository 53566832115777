const routes = [
  {
    path: "/financing",
    name: "Loans",
    component: () => import("@/components/business/loans/index"),
    meta: {
      title: "Tanda | Loans",
      requiresAuth: true,
    },
    children: [
      {
        path: "/financing/loans",
        name: "Loans",
        component: () => import("@/components/business/loans/Loans"),
        meta: {
          title: "Tanda |  Loans",
          requiresAuth: true,
        },
      },
      {
        path: "/financing/open-loans",
        name: "Open Loans",
        component: () => import("@/components/business/loans/OpenLoans"),
        meta: {
          title: "Tanda | Open Loans",
          requiresAuth: true,
        },
      },
      {
        path: "/financing/credit-customers",
        name: "Customers",
        component: () => import("@/components/business/loans/stores"),
        meta: {
          title: "Tanda | Customers",
          requiresAuth: true,
        },
        children: [
          {
            path: "/financing/customers/:storeId/loans",
            name: "Customer Loans",
            component: () =>
              import("@/components/business/loans/stores/loans/Loans"),
            meta: {
              title: "Tanda | Store Loans",
              requiresAuth: true,
            },
          },
          {
            path: "/financing/customers/:storeId/open-loans",
            name: "Open Customer Loans",
            component: () =>
              import("@/components/business/loans/stores/loans/OpenLoans"),
            meta: {
              title: "Tanda | Open Customer Loans",
              requiresAuth: true,
            },
          },
          {
            path: "/financing/customers/:storeId/details",
            name: "Customer Details",
            component: () =>
              import("@/components/business/loans/stores/loans/Answers"),
            meta: {
              title: "Tanda | Customer Details",
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];

export default routes;
