let mutations = {
  SET_ACTIVE_INDEX(state, data) {
    return (state.activeIndex = data);
  },
  SET_SIDENAV_OPEN(state, data) {
    return (state.sideNavOpen = data);
  },
  SET_AUTH_TOKEN(state, data) {
    return (state.authToken = data);
  },
  SET_AUTHENTICATED_USER(state, data) {
    return (state.authenticatedUser = data);
  },
  SET_SELECTED_WALLET(state, data) {
    return (state.selectedWallet = data);
  },
  SET_WALLETS(state, data) {
    return (state.wallets = data);
  },
  SET_ITF_WALLETS(state, data) {
    return (state.itfWallets = data);
  },
  SET_AUTHENTICATION(state, status) {
    state.isAuthenticated = status;
  },
  TANDA_TOKEN_EXPIRY(state, status) {
    state.expiry_in = status;
  },
  SET_BUSINESS_ID(state, data) {
    state.billerId = data;
  },
  SET_SELECTED_TANDA_STORE(state, data) {
    state.selectedTandaStore = data;
  },
  SET_SELECTED_LOAN(state, data) {
    state.selectedLoan = data;
  },
  SET_PERMISSIONS(state, data) {
    state.permissions = data;
  },
};
export default mutations;
