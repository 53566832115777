const routes = [
  {
    path: "/system-users",
    name: "Users",
    component: () => import("@/components/business/users"),
    meta: {
      title: "Tanda | Users",
      requiresAuth: true,
    },
  },
];

export default routes;
