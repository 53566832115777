export default {
  setSideNavOpen({ commit }, data) {
    commit("SET_SIDENAV_OPEN", data);
  },
  setWallets({ commit }, data) {
    commit("SET_WALLETS", data);
  },
  setStores({ commit }, data) {
    commit("SET_STORES", data);
  },
};
