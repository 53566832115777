let getters = {
  expiry_in: (state) => {
    return state.expiry_in;
  },
  activeIndex: (state) => {
    return state.activeIndex;
  },
  sideNavOpen: (state) => {
    return state.sideNavOpen;
  },
  accountNumbers: (state) => {
    return state.accountNumbers;
  },
  isAuthenticated: (state) => {
    return state.isAuthenticated;
  },
  authenticatedUser: (state) => {
    return state.authenticatedUser;
  },
  isFetchingTransactionFee: (state) => {
    return state.isFetchingTransactionFee;
  },
  selectedWallet: (state) => {
    return state.selectedWallet;
  },
  wallets: (state) => {
    return state.wallets;
  },
  itfWallets: (state) => {
    return state.itfWallets;
  },
  billerId: (state) => {
    return state.billerId;
  },
  selectedTandaStore: (state) => {
    return state.selectedTandaStore;
  },
  selectedLoan: (state) => {
    return state.selectedLoan;
  },
  permissions: (state) => {
    return state.permissions;
  },
};
export default getters;
