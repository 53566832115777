const routes = [
  {
    path: "/approvals",
    name: "Approvals",
    component: () => import("@/components/business/requests/Index"),
    meta: {
      title: "Tanda | Approvals",
      requiresAuth: true,
    },
    children: [
      {
        path: "/approvals/my-requests",
        name: "My Requests",
        component: () => import("@/components/business/requests/MyRequests"),
        meta: {
          title: "Tanda |  My Requests",
          requiresAuth: true,
        },
      },
      {
        path: "/approvals/my-approvals",
        name: "My Approvals",
        component: () => import("@/components/business/requests/MyApprovals"),
        meta: {
          title: "Tanda | My Approvals",
          requiresAuth: true,
        },
      },
      {
        path: "/approvals/pending-payments",
        name: "Pending Payments",
        component: () =>
          import("@/components/business/requests/PendingPayments"),
        meta: {
          title: "Tanda | Payments",
          requiresAuth: true,
        },
      },
      {
        path: "/approvals/payments",
        name: "Payments",
        component: () => import("@/components/business/requests/Payments"),
        meta: {
          title: "Tanda | Payments",
          requiresAuth: true,
        },
      },
    ],
  },
];

export default routes;
