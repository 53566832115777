const state = {
  sideNavOpen: true,
  activeIndex: 0,
  verifyEmailData: {},
  authToken: null,
  accountNumbers: [],
  isFetchingAccounts: false,
  isFetchingBalances: false,
  isAuthenticated: false,
  expiry_in: null,
  balance: {},
  authenticatedUser: null,
  isFetchingTransactionFee: false,
  transactionFee: 0,
  selectedWallet: {},
  wallets: [],
  itfWallets: [],
  billerId: null,
  selectedTandaStore: {},
  selectedLoan: {},
  isLender: false,
  permissions: [],
};
export default state;
