import NProgress from "nprogress";
import "nprogress/nprogress.css";
import { createRouter, createWebHistory } from "vue-router";
import reseller from "./reseller/index";
import store from "@/store";

const app = [
  {
    path: "/",
    component: () => import("@/components/main/index"),
    meta: {
      title: "Tanda | Business Portal",
      guest: true,
    },
  },
  {
    path: "/authorized",
    name: "Authorize",
    component: () => import("@/components/main/RedirectPage"),
    meta: {
      title: "Tanda | Authorized",
      guest: true,
    },
  },
  {
    path: "/404",
    component: () => import("@/components/main/notFound"),
    meta: {
      title: "Tanda | 404 Page Not Found",
      guest: true,
    },
  },
  {
    path: "/new-password",
    component: () => import("@/components/main/setPassword"),
    meta: {
      title: "Tanda | Create new password",
      guest: true,
    },
  },
  {
    path: "/reset-password",
    component: () => import("@/components/business/profile/PasswordReset"),
    meta: {
      title: "Tanda | Reset password",
      guest: true,
    },
  },
  {
    path: "/confirm-email",
    component: () => import("@/components/business/profile/ConfirmEmail"),
    meta: {
      title: "Tanda | Email Confirmation",
      guest: true,
    },
  },
  {
    path: "/security-questions",
    component: () => import("@/components/main/securityQuestions"),
    meta: {
      title: "Tanda | Set Security Question",
      guest: true,
    },
  },
  {
    path: "/answer-challenges",
    component: () => import("@/components/main/answerSecurityQuestions"),
    meta: {
      title: "Tanda | Set Security Question",
      guest: true,
    },
  },
  { path: "/:catchAll(.*)", redirect: "/404" },
];
let routes = app.concat(reseller);

const router = createRouter({
  history: createWebHistory("/"),
  routes,
});

router.beforeEach((to, from, next) => {
  document.title = to.meta.title;
  let tokenExpiryMs =
    store.state.expiry_in !== null ? parseInt(store.state.expiry_in) : null;
  const loggedIn = store.state.isAuthenticated;
  const isAuth = to.matched.some((record) => record.meta.requiresAuth);
  const isGuest = to.matched.some((record) => record.meta.guest);
  if (isAuth) {
    if (loggedIn) {
      if (tokenExpiryMs !== null) {
        if (Date.now() >= tokenExpiryMs * 1000) {
          router.push("/").catch(() => {});
        } else {
          next();
        }
      } else {
        router.push("/").catch(() => {});
      }
    } else {
      router.push("/").catch(() => {});
    }
  } else if (isGuest) {
    next();
  }
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    NProgress.start();
  }
  next();
});

// eslint-disable-next-line no-unused-vars
router.afterEach((to, from) => {
  NProgress.done();
});

export default router;
