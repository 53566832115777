import dashboard from "../dashboard";
import loans from "../loans";
import requests from "../requests";
import transactions from "../transactions";
import wallets from "../wallets";
import users from "../users";
import apps from "@/router/reseller/apps";
import settings from "../settings";

const childrenRoutes = [
  {
    path: "/manage-profile",
    name: "My Profile",
    component: () => import("@/components/business/profile/MyProfile"),
    meta: {
      title: "Tanda | Manage Profile",
      requiresAuth: true,
    },
    children: [
      {
        path: "/edit-profile",
        name: "Edit Profile",
        component: () => import("@/components/business/profile/ManageProfile"),
        meta: {
          title: "Tanda | Edit profile",
          requiresAuth: true,
        },
      },
    ],
  },
];
const indexRoutes = [];
const routes = indexRoutes.concat(
  childrenRoutes,
  dashboard,
  transactions,
  loans,
  wallets,
  requests,
  users,
  apps,
  settings
);
export default routes;
